<template>
  <mobile-screen :header="true" screen-class="resources-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("roles", pageName, pageName) }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      ref="addResourceTypeForm"
      @submit="submitForm"
      :key="formKey"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      :initial-values="selectedRole && { ...selectedRole }"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item"
          :class="{ 'has-error': errors.name }"
        >
          <span class="error-message" v-if="errors.name">
            {{ errors.name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{ displayLabelName("roles", pageName, "name") }}
              </label>
              <Field
                name="name"
                as="input"
                type="text"
                rules="required"
                :placeholder="displayLabelName('roles', pageName, 'name')"
              />
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <router-link
            :to="{ name: addPermissionLinkName }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedPermissions && selectedPermissions.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "containers",
                    pageName,
                    "add-permissions-to-role"
                  )
                }}
              </div>
              <p class="text-label">
                {{
                  selectedPermissions
                    .map(item => (item.name ? item.name : item.permission.name))
                    .join(", ")
                }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "containers",
                  pageName,
                  "add-permissions-to-role"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import rolesMixin from "@/services/mixins/roles/rolex-mixin";
import { mapActions, mapState } from "vuex";
export default {
  name: "EditRole",
  data() {
    return {
      formKey: 0
    };
  },
  computed: {
    ...mapState("roles", ["selectedRole", "selectedPermissions"]),
    pageName() {
      const { containerType } = this;
      if (containerType === "USER") {
        return "edit-role-user";
      } else if (containerType === "RESOURCE") {
        return "edit-role-resource";
      } else if (containerType === "LEVEL") {
        return "edit-role-level";
      } else if (containerType === "USER_MANAGER") {
        return "edit-role-user-manager";
      } else if (containerType === "RECEPTION_DESK") {
        return "edit-reception-desk-role";
      }
      return "";
    },
    addPermissionLinkName() {
      const { containerType } = this;
      if (containerType === "USER") {
        return "r_edit-user-role-add-permissions-to-role";
      } else if (containerType === "RESOURCE") {
        return "r_edit-resource-add-permissions-to-role";
      } else if (containerType === "LEVEL") {
        return "r_edit-level-add-permissions-to-role";
      } else if (containerType === "USER_MANAGER") {
        return "r_edit-user-manager-role-add-permissions-to-role";
      } else if (containerType === "RECEPTION_DESK") {
        return "r_edit-reception-desk-role-add-permissions-to-role";
      }
      return "";
    }
  },
  watch: {
    selectedRole: {
      immediate: true,
      handler() {
        if (this.formKey === 10) {
          this.formKey = 0;
        } else {
          this.formKey++;
        }

        if (this.selectedRole && this.selectedRole.id) {
          this.getPermissionsInRole(this.selectedRole.id);
        }
      }
    }
  },
  mounted() {
    if (!this.selectedRole) {
      this.$router.push({ name: this.backLinkName });
    }
  },
  methods: {
    ...mapActions("roles", [
      "getPermissionsInRole",
      "updatePermissionsInRoles"
    ]),
    submitForm(values) {
      if (values) {
        this.submitCompanyData(values);
      }
    },
    submitCompanyData(values) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(`${apiEndpoints.company.roles}/${this.selectedRole.id}`, values)
        .then(() => {
          this.updatePermissionsInRoles(this.selectedRole.id).finally(() => {
            this.getPermissionsInRole(this.selectedRole.id);
          });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.fetchRoles();
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [rolesMixin]
};
</script>
